import React from 'react';
import styled from 'styled-components';

import Container from '../components/Container';
import Seo from '../components/Seo';
import config from '../config';
import { typography } from '../styles/typography';

const Content = styled.div`
  width: 100%;
  height: 100%;

  > div {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    > h1 {
      ${typography.h4};
    }
  }
`;

const PageNotFound = () => (
  <>
    <Seo title="404" description={`This page doesn't seem to exist...`} />
    <Content>
      <Container verticalPadding={false}>
        <h1>This page doesn't exist 🧐</h1>
      </Container>
    </Content>
  </>
);

export default PageNotFound;
